import React,{useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import styled from 'styled-components';
import CafeManags from '../assets/images/cm.jpg'
import crCat from '../assets/images/ser2.jpg'
import coffe from '../assets/images/coffee.jpg'
import bev2 from '../assets/images/Picture4.jpg'
import bev3 from '../assets/images/Picture1.jpg'
import bev4 from '../assets/images/Picture6.jpg'
import bev5 from '../assets/images/Picture5.jpg'
import bev6 from '../assets/images/Picture8.jpg'
import bev7 from '../assets/images/Picture9.jpeg'
import caf1 from '../assets/images/snacks.png'
import caf2 from '../assets/images/food_truck.png'
import caf3 from '../assets/images/popc.jpg'


const CafeManagTop = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${CafeManags});
  background-size: cover;
  background-position: center;
  position: relative;

  & .CafeManag-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(rgba(255,255,255,0.6), white);
  }
  @media screen and (max-width:767px) {
  .CafeManag-overlay{
  width: 100%;
  height: 102%;
  }
}
@media screen and (min-width:767px) and (min-width:1023px) {
  .CafeManag-overlay{
  width: 100%;
  height: 102%;
  }
}
`;

const CafeManagH = styled.div`
  font-size: 3vw;
  font-family: "heebo";
  font-weight: 300;
  position: relative;

  & span{
      color: #c5111c;
      font-weight: 900;
  }
  @media screen and (max-width:767px) {
  font-size: 7vw;
}

@media screen and (min-width:767px) and (max-width:1023px) {
font-size: 5vw;
}
`;

const CafeManagPara = styled.div`
  font-weight: 500;
  font-family: "Source Sans 3";
  font-size: 1.1vw;
  position: relative;
`;

const Catering = styled.div`
width: 100%;
height: auto;
display: flex;
align-items: center;
justify-content: center;
padding: 3vw 0vw;

.catering-cont{
    width: 80%;
   min-height: 20vw;
   display: flex;
   align-items: center;
   
}
.catering-left{
    width: 50%;
    height: 20vw;
}
.catering-left h2{
    font-size: 2vw;
    font-family: "Montserrat";
    text-transform: uppercase;
    margin-top: 5vw;
}
.catering-left p{
    font-size: 1.1vw;
}
.catering-right{
    width: 50%;
    height: 20vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cr-img{
    width: 80%;
    height: 100%;
    background-image: url(${crCat});
    background-size: cover;
}
@media screen and (max-width:767px) {
 .catering-cont{
    flex-direction: column;
 }
 .catering-left{
    width: 100%;
    height: 55vw;
 }
 .catering-left h2{
    font-size: 6vw;
 }
 .catering-left p{
    font-size: 3.5vw;
 }
 .catering-right {
    width: 100%;
    height: 44vw;
 }
}

@media screen and (min-width:767px) and (max-width:1023px) {
    .catering-cont{
    flex-direction: column;
 }
 .catering-left{
    width: 100%;
    height: 45vw;
 }
 .catering-left h2{
    font-size: 4.5vw;
 }
 .catering-left p{
    font-size: 2.5vw;
 }
 .catering-right {
    width: 100%;
    height: 35vw;
 }
}
`; 

const Beverage = styled.div`

width: 100%;
height: auto;
display: flex;
align-items: center;
justify-content: center;

.beverage-cont{
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
}
.beverage-cont h2{
    font-size: 2vw;
    font-family: "Montserrat";
    text-transform: uppercase;
    margin-top: 5vw;
}
.beverage-cont p{
    text-align: center;
    font-size: 1.1vw;
}
.beverage-boxes{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 3vw;
    margin: 4vw 0vw;
}
.beverage-box{
    width: 45%;
    height: 50vw;
    background-color: aliceblue;
    border-radius: 1vw;
    box-shadow: 0.3vw 0.3vw 0.5vw #00000017;
    overflow: hidden;
}
.beverage-box:nth-child(7){
    width: 95%;
    height: auto;
}
.beverage-box:nth-child(7) .beverage-cont-box{
    height: auto;
}
.beverage-img-box{
    width: 100%;
    height: 25vw;
    position: relative;
    overflow: hidden;
}
.beverage-img-box img{
    width: 100%;
}
.beverage-cont-box{
    width: 80%;
    height: 20vw;
    padding: 2vw;
}
.beverage-box h2{
    margin-top: 0;
    font-size: 1.2vw;
}
.beverage-box p{
    text-align: left;
    font-size: 1vw;
    font-family: "source sans 3";
    line-height: 1.5;
}
@media screen and (max-width:767px) {
    .beverage-cont h2{
        font-size: 4vw;
        text-align: center;
    }
    .beverage-cont p{
    font-size: 3.5vw;
}
.beverage-box {
    width: 100%;
    height: 159vw;
}
.beverage-img-box {
    width: 100%;
    height: 44vw;
}
}

@media screen and (min-width:767px) and (max-width:1023px) {
    .beverage-cont h2{
        font-size: 3vw;
        text-align: center;
    }
    .beverage-cont p{
    font-size: 2.5vw;
}
.beverage-box {
    width: 100%;
    height: 120vw;
}
.beverage-img-box {
    width: 100%;
    height: 44vw;
}
}
`
const CafBoxes = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;


.CafBox{
    width: 75%;
    height: 33vw;
    display: flex;
    padding: 2vw 0vw;
}
 
.cafCont{
    width: 50%;
    height: 33vw;
}
.cafCont h2{
    font-size: 3vw;
}
.cafCont p{
    font-size: 1.1vw;
}
.cafImg{
    width: 50%;
    height: 33vw;
}

.cbh2{
    margin-top: 2vw;
}
.cafImg{
    display: flex;
    align-items: center;
   justify-content: flex-end;
}
.CafBox:nth-child(2) .cafImg{
 justify-content:flex-start ;
}
.ci-img-box{
    width: 80%;
    height: 23vw;
    overflow: hidden;
    border: 6px solid rgba(0, 0, 0, 0.19);
    display: flex;
    align-items: center;
    justify-content: center;
}
.ci-img-box img{
    width: 100%;
}
@media screen and (max-width:767px) {
  .CafBox{
    flex-direction: column;
    height: auto;
    padding: 7vw 0vw;
    gap: 3vw;
  }
  .cafCont{
    width: 100%;
    height: auto;
  }
  .cafImg{
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
  }
  .cafCont h2 {
    font-size: 5vw;
}
.cafCont p {
    font-size: 3.5vw;
}
.ci-img-box{
    height: 60vw;
}
.CafBox:nth-child(2){
    flex-direction: column-reverse;
}
.CafBox:nth-child(2) .cafImg{
    justify-content: center;
}
}

@media screen and (min-width:767px) and (max-width:1023px) {
    .CafBox{
    flex-direction: column;
    height: auto;
    padding: 7vw 0vw;
    gap: 3vw;
  }
  .cafCont{
    width: 100%;
    height: auto;
  }
  .cafImg{
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
  }
  .cafCont h2 {
    font-size: 4vw;
}
.cafCont p {
    font-size: 2.5vw;
}
.ci-img-box{
    height: 45vw;
}
.CafBox:nth-child(2){
    flex-direction: column-reverse;
}
.CafBox:nth-child(2) .cafImg{
    justify-content: center;
}
}
`;

export default function CafeManagement() {
      useEffect(() => {
        AOS.init({
          duration: 500,
        });
    
      }, []);
  return (
    <div>
       <CafeManagTop>
        <div className="CafeManag-overlay">

        </div>
       <CafeManagH>
       CAFETERIA  <span>MANAGEMENT</span> 
       </CafeManagH>
       <CafeManagPara>
       {/* From Concept to Completion, We've Got You Covered */}
       </CafeManagPara> 
      </CafeManagTop>
      <Catering>
        <div className="catering-cont">
        <div className="catering-left">
         <h2>Catering services </h2>
         <p>
        <b>Corporate Catering Services</b> 
         <br />
         <br />
Delight your employees and clients with our customized corporate catering services, offering a range of cuisines and dietary options, from breakfast to dinner, and everything in between.

         </p>
        </div>
        <div className="catering-right">
       <div className="cr-img">

       </div>
        </div>
        </div>
       </Catering>
       <Beverage>
      <div className="beverage-cont">
  <h2> Our Beverages: A World of Varieties</h2>
  <p>Welcome to the world of beverages, where creativity meets flavor in every sip. From hot and comforting drinks to refreshing and cool concoctions, our beverage varieties cater to every taste and occasion. Here’s a glimpse into how we craft these delightful drinks:
  </p>
  <div className="beverage-boxes">
    <div className="beverage-box">
<div className="beverage-img-box">
<img src={coffe} alt="" />
</div>
<div className="beverage-cont-box">
    <h2 className="beverage-h">
    Coffee Creations
    </h2>
    <p>
    Coffee is more than just a morning ritual; it’s an art. We offer a range of coffee varieties to satisfy every caffeine enthusiast.
<ul>
    <li><b>Espresso:</b>  A rich, concentrated coffee shot that forms the base for many other drinks.</li>
    <li><b>Latte:</b>  A smooth blend of espresso and steamed milk, topped with a light layer of foam.</li>
    <li><b>Cappuccino:</b>  Balanced with equal parts espresso, steamed milk, and foam, perfect for those who love a creamy texture.</li>
    <li><b>Cold Brew:</b>  Slow-steeped in cold water for a smooth and refreshing taste.</li>
</ul>


    </p>
</div>
    </div>
    <div className="beverage-box">
<div className="beverage-img-box">
<img src={bev2} alt="" />
</div>
<div className="beverage-cont-box">
    <h2 className="beverage-h">
    Filter Coffee: Understanding Filter Coffee
    </h2>
    <p>
    Filter coffee, also known as drip brew coffee, is a popular method of brewing coffee that emphasizes simplicity and flavor. This method involves pouring hot water over ground coffee beans, allowing the water to extract the flavors as it passes through the coffee grounds.
South Indian Filter Coffee**: Known locally as "Kaapi," this variation is made using a metal coffee filter and often mixed with boiled milk and sugar for a frothy, sweet beverage.


    </p>
</div>
    </div>
    <div className="beverage-box">
<div className="beverage-img-box">
<img src={bev3} alt="" />
</div>
<div className="beverage-cont-box">
    <h2 className="beverage-h">
    Tea Time
    </h2>
    <p>
    Tea lovers rejoice! Our selection of teas brings out the best of traditional and modern flavors.
<ul>
<li><b>Black Tea:</b> Bold and robust, perfect for a strong morning start</li>
<li><b>Green Tea:</b> Light and earthy, known for its health benefits.</li>
<li><b>Herbal Tea: </b>Caffeine-free and infused with natural herbs and flowers for a soothing experience.</li>
<li><b>Matcha:</b> Finely ground green tea offering a vibrant and energizing drink.</li>
</ul>


    </p>
</div>
    </div>
    <div className="beverage-box">
<div className="beverage-img-box">
<img src={bev4} alt="" />
</div>
<div className="beverage-cont-box">
    <h2 className="beverage-h">
    Refreshing Juices
    </h2>
    <p>
    Our juice bar is brimming with fresh, fruity flavors designed to rejuvenate and energize.
<ul>
    <li><b> Orange Juice:</b> Classic and packed with vitamin C for a healthy boost.</li>
    <li><b> Apple Juice:</b> Sweet and crisp, a favorite among all ages.</li>
    <li><b>Green Juice: </b>   A nutritious blend of spinach, kale, apple, and lemon for a detoxifying effect.</li>
    <li><b>Berry Blast:</b>  A mix of strawberries, blueberries, and raspberries for a tangy treat.</li>
</ul>


    </p>
</div>
    </div>
    <div className="beverage-box">
<div className="beverage-img-box">
<img src={bev5} alt="" />
</div>
<div className="beverage-cont-box">
    <h2 className="beverage-h">
    Smoothie Sensations
    </h2>
    <p>
    Smoothies are a perfect blend of flavor and nutrition, ideal for a quick meal or a refreshing snack.
<ul>
    <li><b>Tropical Delight:</b>   Pineapple, mango, and banana create a sunny escape in a glass.</li>
    <li><b>Berry Banana: </b>  A mix of strawberries, blueberries, and bananas for a creamy delight</li>
    <li><b>Green Goddess: </b> Spinach, avocado, and apple provide a healthy and fulfilling option.</li>
    <li><b>Peanut Butter Power:</b>  A protein-packed blend of banana, peanut butter, and almond milk.</li>
</ul>


    </p>
</div>
    </div>
    <div className="beverage-box">
<div className="beverage-img-box">
<img src={bev7} alt="" />
</div>
<div className="beverage-cont-box">
    <h2 className="beverage-h">
    Innovative Mocktails
    </h2>
    <p>
    Our mocktails are crafted to deliver the elegance and taste of cocktails, without the alcohol.
<ul>
<li><b>Virgin Mojito:</b> Mint, lime, and soda water create a refreshing and zesty drink. </li>
<li><b>Cucumber Cooler:</b> Cucumber, mint, and lime for a calming and rejuvenating experience. </li>
<li><b>Sunset Sparkle:</b> Orange juice, grenadine, and soda water for a visually stunning and delightful drink. </li>
<li><b>Pineapple Fizz:</b> Pineapple juice, ginger ale, and lime for a tropical twist. </li>

</ul>


    </p>
</div>
    </div>
    <div className="beverage-box">
<div className="beverage-img-box">
<img src={bev6} alt="" />
</div>
<div className="beverage-cont-box">
    <h2 className="beverage-h">
    EARTHY ELIXIRS
    </h2>
    <p>
    “Embracing Nature’s Wisdom: Discover the Power of Herbal Beverages. 
<br /><br />
In a world dominated by processed drinks, herbal beverages offer a refreshing alternative. These natural infusions not only tantalize our taste buds but also provide numerous health benefits. From boosting immunity to promoting relaxation, herbal beverages are the perfect way to nourish body, mind and spirit.” They are rich in antioxidants, vitamins and minerals, these drinks can help stay hydrated, focused and energized. 
<br />
For centuries, herbal beverages have been a cornerstone of traditional medicine. By incorporating herbal beverages in our daily routine, we can experience improved well-being, increased energy, and a deeper connection to nature.

<ul>
<li>Turmeric Latte</li>
<li>Kashaya</li>
<li>Rooibos Tea</li>
<li>Chamomile Tea </li>
<li>Peppermint Tea</li>
<li>Lemongrass Tea</li>

</ul>
Explore our beverage varieties and indulge in the delightful flavors that each one offers. Whether you’re starting your day or winding down, we have the perfect drink to complement your mood and occasion. Cheers!

    </p>
</div>
    </div>
  </div>
      </div>
       </Beverage>
       <CafBoxes>
    <div className="CafBox">
        <div className="cafCont">
<h2>TUCK SHOP</h2>
<h2 className='cbh2'>SnackShack</h2>
<p>
Our tuck shop is dedicated to serving our employees with a variety of convenient and delicious options. From short snacks to satisfying meals, we’ve got you covered. 

<ul>
    <li>Crunchy Snacks (chips, nuts, etc.)</li>
    <li>Freshly made sandwiches</li>
    <li>Protein packed boiled eggs</li>
    <li>Instant Noodles and more</li>
    <li>MRP Items for your break time snacks</li>
</ul>
Take a break and recharge at our “SnackShack” Tuck Shop!

</p>
        </div>
        <div className="cafImg">
   <div className="ci-img-box">
<img src={caf1} alt="" />
   </div>
        </div>
    </div>
    <div className="CafBox">
    <div className="cafImg">
   <div className="ci-img-box">
<img src={caf2} alt="" style={{width:"70%"}} />
   </div>
    </div>
   <div className="cafCont">
<h2>FOOD TRUCK </h2>

<p>
At SVLNS Corporate Solutions, we take pride in curating engaging and memorable experiences for your employees. One of our standout offerings is the Food Truck Experience, designed to bring a delightful culinary adventure right to your company premises.
<br /><br /> We organize an array of food trucks featuring diverse cuisines to suit every palate. From smoky and flavorful barbecue bikes to refreshing ice cream trucks, and a variety of other specialty food trucks offering delectable treats, there’s something for everyone.
<br /><br />This monthly initiative allows employees to step outside, relax, and indulge in delicious meals, fostering a sense of community and enjoyment at the workplace. Additionally, we can tailor this experience for your Annual Day, Family Day, or any other special event, making it a unique and fun-filled celebration for all attendees.
<br /><br />Let us help you create a vibrant and unforgettable atmosphere, ensuring your team feels valued and connected while enjoying the best flavors from across cuisines.



</p>
 </div>
    </div>
    <div className="CafBox">
        <div className="cafCont">
<h2>POP UP COUNTERS  </h2>

<p>
At SVLNS Corporate Solutions, we believe in enhancing workplace dining experiences by introducing variety and excitement to employees' meals. That’s why we organize Pop-Up Counters once a week in your cafeteria.
<br /><br />
These counters feature a rotating menu of delightful options, including a flavorful Biriyani Counter, a vibrant Chaat Station, popular Street Food favorites, or even State-Specific Cuisines to showcase regional flavors.
<br /><br />
This initiative provides employees with a refreshing change from their regular meals, making lunchtime more enjoyable and eagerly anticipated. It’s a fantastic way to break the monotony, and add a dash of culinary adventure to the workplace.
<br /><br />
Let us help you bring flavor and fun to your cafeteria with our carefully curated pop-up counter experiences!


</p>
        </div>
        <div className="cafImg">
   <div className="ci-img-box">
<img src={caf3} alt="" />
   </div>
        </div>
    </div>
       </CafBoxes>
    </div>
  )
}
