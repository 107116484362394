import React from 'react'
import styled from 'styled-components';
import technology from '../assets/images/technology.jpg'
import pdf1 from '../assets/images/pdf/CAFE LINK - PROCESS FLOW (1)_page-0001.jpg'
import pdf2 from '../assets/images/pdf/CAFE LINK - PROCESS FLOW (1)_page-0002.jpg'
import pdf3 from '../assets/images/pdf/CAFE LINK - PROCESS FLOW (1)_page-0003 copy.jpg'
import pdf4 from '../assets/images/pdf/CAFE LINK - PROCESS FLOW (1)_page-0004.jpg'
import pdf5 from '../assets/images/pdf/CAFE LINK - PROCESS FLOW (1)_page-0005.jpg'
import pdf6 from '../assets/images/pdf/CAFE LINK - PROCESS FLOW (1)_page-0006.jpg'
import pdf7 from '../assets/images/pdf/CAFE LINK - PROCESS FLOW (1)_page-0007.jpg'
import pdf8 from '../assets/images/pdf/CAFE LINK - PROCESS FLOW (1)_page-0008.jpg'
import pdf9 from '../assets/images/pdf/CAFE LINK - PROCESS FLOW (1)_page-0009.jpg'
import pdf10 from '../assets/images/pdf/CAFE LINK - PROCESS FLOW (1)_page-0010.jpg'
import pdf11 from '../assets/images/pdf/CAFE LINK - PROCESS FLOW (1)_page-0011.jpg'
import pdf12 from '../assets/images/pdf/CAFE LINK - PROCESS FLOW (1)_page-0012.jpg'


const TechTop = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${technology});
  background-size: cover;
  background-position: center;
  position: relative;

  & .Tech-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(rgba(255,255,255,0.6), white);
  }

  @media screen and (max-width:767px) {
  .Tech-overlay{
  width: 100%;
  height: 102%;
  }
}
@media screen and (min-width:767px) and (min-width:1023px) {
  .Tech-overlay{
  width: 100%;
  height: 102%;
  }
}
`;

const TechH = styled.div`
  font-size: 3vw;
  font-family: "heebo";
  font-weight: 300;
  position: relative;

  & span{
      color: #c5111c;
      font-weight: 900;
  }
 @media screen and (max-width:767px) {
font-size: 7vw;
}
@media screen and (min-width:767px) and (max-width:1023px){
font-size: 5vw;
}
`;

const TechPara = styled.div`
  font-weight: 500;
  font-family: "Source Sans 3";
  font-size: 1.1vw;
  position: relative;
`;
const TechnologyContainer = styled.div`
width: 100%;
height: auto;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;

.techTopc{
  width: 100%;
  height: auto;
  padding: 3vw 0vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.techTopc h2{
  width: 80%;
  font-size: 2vw;
}

.techTopc p{
  width: 80%;
  font-size: 1vw;
  line-height: 1.6;
}
.ttc{
  background: aliceblue;
}
.tech-pdf{
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tech-pdf img{
  width: 100%;
}
@media screen and (max-width:767px) {
  .techTopc h2{
  font-size: 5vw;
}

.techTopc p{
  font-size: 3.5vw;
  line-height: 1.6;
}
}
@media screen and (min-width:767px) and (max-width:1023px){
  .techTopc h2{
  font-size: 4vw;
}

.techTopc p{
  font-size: 2.5vw;
  line-height: 1.6;
}
}
`
export default function Technology() {
  return (
    <div>
        <TechTop>
        <div className="Tech-overlay">

        </div>
       <TechH>
<span>TECHNOLOGY</span> 
       </TechH>
       <TechPara>
       {/* From Concept to Completion, We've Got You Covered */}
       </TechPara>
      </TechTop>
      <TechnologyContainer>
        <div className="techTopc">
          <h2 className="techtop-h">
          Digitalization of Cafeteria for Corporates
          </h2>
          <p>In the modern corporate world, digitalization has become a key driver in enhancing efficiency and improving employee experience. One area where this transformation is particularly impactful is in the corporate cafeteria. By leveraging digital technologies, companies can create a seamless dining experience that saves time, reduces waste, and promotes healthier eating habits.</p>
        </div>
        <div className="techTopc ttc">
          <h2 className="techtop-h">
          Benefit of Digitalizing Corporate Cafeterias
          </h2>
          <p>Digitalization allows employees to access menus, order food, and make payments through mobile apps or kiosks. This reduces wait times and streamlines the entire dining process, allowing employees to quickly get back to work or enjoy their break without standing in long lines.
<br /><br />
The digitalization of corporate cafeterias presents numerous benefits, from enhanced convenience and personalized experiences to more efficient operations and safer payment methods. By embracing these digital solutions, companies can create a modern dining experience that meets the needs of today's workforce and supports a positive corporate culture.
</p>
        </div>
        <div className="tech-pdf">
          <img src={pdf1} alt="" />
          <img src={pdf2} alt="" />
          <img src={pdf3} alt="" />
          <img src={pdf4} alt="" />
          <img src={pdf5} alt="" />
          <img src={pdf6} alt="" />
          <img src={pdf7} alt="" />
          <img src={pdf8} alt="" />
          <img src={pdf9} alt="" />
          <img src={pdf10} alt="" />
          <img src={pdf11} alt="" />
          <img src={pdf12} alt="" />
        </div>
      </TechnologyContainer>
    </div>
  )
}
