import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import styled from 'styled-components';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import services from '../assets/images/services.jpg';
import ser1 from '../assets/images/ser1.jpg';
import ser2 from '../assets/images/ser2.jpg';
import cem from '../assets/images/ser-em.jpg';
import hcp from '../assets/images/housekeepingc.jpg';
import cg from '../assets/images/cg.jpg';
import fleet from '../assets/images/fleet.jpg';
import tech from '../assets/images/tech.jpg';
import techcc from '../assets/images/techcc.jpg';

gsap.registerPlugin(ScrollTrigger);

const ServiceTop = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${services});
  background-size: cover;
  background-position: center;
  position: relative;

  & .service-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(rgba(255,255,255,0.6), white);
  }

  @media screen and (max-width:767px) {
  .service-overlay{
  width: 100%;
  height: 102%;
  }
}
@media screen and (min-width:767px) and (min-width:1023px) {
  .service-overlay{
  width: 100%;
  height: 102%;
  }
}
`;

const ServiceH = styled.div`
  font-size: 3vw;
  font-family: "heebo";
  font-weight: 300;
  position: relative;

  & span{
      color: #c5111c;
      font-weight: 900;
  }

  @media screen and (max-width:767px) {
  font-size: 7vw;
}

@media screen and (min-width:767px) and (max-width:1023px) {
font-size: 5vw;
}
`;

const ServicePara = styled.div`
  font-weight: 500;
  font-family: "Source Sans 3";
  font-size: 1.1vw;
  position: relative;

  @media screen and (max-width:767px) {
  font-size: 3.5vw;
}

@media screen and (min-width:767px) and (max-width:1023px) {
font-size: 2.5vw;
}
`;

const ServiceBoxes = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ServiceBox = styled.div`
  width: 80%;
  height: 22vw;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  

  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: black;
  }
  .service-dot {
    width: 1vw;
    height: 1vw;
    border-radius: 50%;
    position: absolute;
    border: 2px solid #dfc789;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
  }
  .service-fill {
    width: 0.5vw;
    height: 0.5vw;
    border-radius: 50%;
    background-color: #dfc789;
  }
  .service-img-box {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .services-img {
    width: 80%;
    height: 90%;
    overflow: hidden;
  }
  .s-img {
    width: 100%;
  }
  .service-content {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .service-des {
    width: 80%;
  }
  .ser-h {
    font-family: "heebo";
    font-size: 1.5vw;
    font-weight: 800;
  }
  .ser-para {
    font-family: "Source Sans 3";
    font-size: 1.1vw;
    line-height: 1.5;
  }
  .ser-h span {
    position: absolute;
    font-size: 10vw;
    z-index: -1;
    opacity: 0.1;
    top: 0vw;
  }

  @media screen and (max-width:767px) {
 height: auto;
 width: 90%;
 padding: 5vw 0vw;

 .ser-h{
  font-size: 3.5vw;
 }
 .ser-para{
  font-size: 3.5vw;
 }
 .s-img{
  width: 130%;
 }
 .service-dot{
  width: 3vw;
  height: 3vw;
 }
 .service-fill{
  width: 1vw;
  height: 1vw;
 }
 .ser-h span{
  font-size: 20vw;
  top: -10vw;
 }
}

@media screen and (min-width:767px) and (max-width:1023px) {
  height: auto;
 width: 90%;
 padding: 4vw 0vw;

 .ser-h{
  font-size: 3.5vw;
 }
 .ser-para{
  font-size: 2.5vw;
 }
 .s-img{
  width: 110%;
 }
 .service-dot{
  width: 2vw;
  height: 2vw;
 }
 .service-fill{
  width: 1vw;
  height: 1vw;
 }
 .ser-h span{
  font-size: 20vw;
  top: -10vw;
 }
}
`;

export default function Services() {
  useEffect(() => {
    AOS.init({
      duration: 500,
    });

  }, []);
  return (
    <div>
      <ServiceTop>
        <div className="service-overlay">

        </div>
       <ServiceH>
OUR <span>SERVICES</span> 
       </ServiceH>
       <ServicePara>
       From Concept to Completion, We've Got You Covered
       </ServicePara>
      </ServiceTop>
      <ServiceBoxes>
        <ServiceBox>
          <div className="service-dot">
            <div className="service-fill">

            </div>
          </div>
        <div className="service-img-box" data-aos="fade-right">
         <div className="services-img">
         <img src={ser1} alt="" className="s-img" />
         </div>
        </div>
        <div className="service-content" data-aos='fade-left'>
          <div className="service-des">
              <div className="ser-h"><span>1</span> Facility Management Services</div>
          <div className="ser-para">We provide comprehensive facility management solutions, including housekeeping, pest control, and F&B services, ensuring seamless operations and a productive work environment.</div>
          </div>
        </div>
        </ServiceBox>
        <ServiceBox>
          <div className="service-dot">
            <div className="service-fill">

            </div>
          </div>
        <div className="service-content" data-aos="fade-right">
          <div className="service-des">
              <div className="ser-h"><span>2</span>  Corporate Catering Services</div>
          <div className="ser-para">Delight your employees and clients with our customized corporate catering services, offering a range of cuisines and dietary options, from breakfast to dinner, and everything in between.</div>
          </div>
        </div>
        <div className="service-img-box" data-aos='fade-left'>
         <div className="services-img">
         <img src={ser2} alt="" className="s-img" />
         </div>
        </div>
        </ServiceBox>
        <ServiceBox>
          <div className="service-dot">
            <div className="service-fill">

            </div>
          </div>
        <div className="service-img-box" data-aos="fade-right">
         <div className="services-img">
         <img src={fleet} alt="" className="s-img" />
         </div>
        </div>
        <div className="service-content" data-aos='fade-left'>
          <div className="service-des">
              <div className="ser-h"><span>3</span> Corporate Transport Services</div>
          <div className="ser-para">Streamline your company's transportation needs with our reliable and efficient corporate transport services, including employee pickups, drops, and event transportation.</div>
          </div>
        </div>
        </ServiceBox>
        <ServiceBox>
          <div className="service-dot">
            <div className="service-fill">

            </div>
          </div>
        <div className="service-content" data-aos="fade-right">
          <div className="service-des">
              <div className="ser-h"><span>4</span>Event Management</div>
          <div className="ser-para">From conferences to celebrations, our expert event management team crafts unforgettable experiences, handling logistics, décor, and entertainment to perfection.</div>
          </div>
        </div>
        <div className="service-img-box" data-aos='fade-left'>
         <div className="services-img">
         <img src={cem} alt="" className="s-img" />
         </div>
        </div>
        </ServiceBox>
        {/* <ServiceBox>
          <div className="service-dot">
            <div className="service-fill">

            </div>
          </div>
        <div className="service-img-box" data-aos="fade-right">
         <div className="services-img">
         <img src={hcp} alt="" className="s-img" />
         </div>
        </div>
        <div className="service-content" data-aos='fade-left'>
          <div className="service-des">
              <div className="ser-h"><span>5</span> Supply of Pantry, Stationery, and Housekeeping Consumables</div>
          <div className="ser-para">Source all your office essentials from us, including pantry items, stationery, and housekeeping supplies, ensuring convenience and cost-effectiveness.</div>
          </div>
        </div>
        </ServiceBox> */}
        <ServiceBox>
          <div className="service-dot">
            <div className="service-fill">

            </div>
          </div>
          <div className="service-img-box" data-aos='fade-left'>
         <div className="services-img">
         <img src={cg} alt="" className="s-img" />
         </div>
        </div>
        <div className="service-content" data-aos="fade-right">
          <div className="service-des">
              <div className="ser-h"><span>5</span>Corporate Gifting</div>
          <div className="ser-para">Show appreciation and build relationships with our thoughtful corporate gifting solutions for events, festivals, or special occasions, tailored to your brand and budget.</div>
          </div>
        </div>
        </ServiceBox>
        <ServiceBox>
          <div className="service-dot">
            <div className="service-fill">

            </div>
          </div>
          <div className="service-content" data-aos='fade-left'>
          <div className="service-des">
              <div className="ser-h"><span>6</span> Corporate/Apartment/Tech Park Landscape Solutions and Management</div>
          <div className="ser-para">Transform your outdoor spaces with our innovative landscape design and management services, enhancing aesthetics, functionality, and employee well-being.</div>
          </div>
        </div>
        <div className="service-img-box" data-aos="fade-right">
         <div className="services-img">
         <img src={tech} alt="" className="s-img" />
         </div>
        </div>
     
        </ServiceBox>
        <ServiceBox>
          <div className="service-dot">
            <div className="service-fill">

            </div>
          </div>
          <div className="service-img-box" data-aos='fade-left'>
         <div className="services-img">
         <img src={techcc} alt="" className="s-img" />
         </div>
        </div>
        <div className="service-content" data-aos="fade-right">
          <div className="service-des">
              <div className="ser-h"><span>7</span> Technology for Corporate Catering</div>
          <div className="ser-para">Revolutionize your catering operations with our cutting-edge technology solutions, featuring online ordering, menu management, and real-time tracking.</div>
          </div>
        </div>
  
        </ServiceBox>
        <ServiceBox>
          <div className="service-dot">
            <div className="service-fill">

            </div>
          </div>
          <div className="service-content" data-aos='fade-left'>
          <div className="service-des">
              <div className="ser-h"><span>8</span>Corporate Travel Bookings and Offsite Training Arrangements</div>
          <div className="ser-para">Simplify business travel and training logistics with our expert services, covering flight bookings, accommodation, and offsite training arrangements, ensuring seamless experiences</div>
          </div>
        </div>
        <div className="service-img-box" data-aos="fade-right">
         <div className="services-img">
         <img src={fleet} alt="" className="s-img" />
         </div>
        </div>
     
        </ServiceBox>
      </ServiceBoxes>
    </div>
  )
}
