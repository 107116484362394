import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import logo from '../assets/images/slnis_logo.png';
import { Link } from 'react-router-dom';

const NavContainer = styled.div`
  width: 100%;
  position: fixed;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
  transition: all 0.3s ease;
  top: 0;
  z-index: 15;
  

  &.scrolled {
    background-color: rgba(255, 255, 255, 0.7);
    padding-top: 0;
    backdrop-filter: blur(10px);
  }

  @media screen and (max-width:767px) {
    width: 100%;
    height: 100vh;
    padding-top: 0;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    transform: ${({ isActive }) => (isActive ? "" : "translateX(-100vw)")};
    transition: all 0.3s ease;
  }
  @media screen and (min-width: 767px) and (max-width: 1023px) {
    width: 100%;
    height: 100vh;
    padding-top: 0;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
    transform: ${({ isActive }) => (isActive ? "" : "translateX(-100vw)")};
    transition: all 0.3s ease;
}
`;

const Img = styled.img`
  width: 60px;
  margin-left: 40px;

  @media screen and (max-width:767px) {
   display: none;
  }
  @media screen and (min-width: 767px) and (max-width: 1023px){
    display: none;
  }
`;

const NavList = styled.ul`
  width: 70%;
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: space-around;

  & li {
    color: black;
    font-family: 'Source Sans 3';
    font-size: 15px;
    font-weight: 600;
    position: relative;
    cursor: pointer;
  }
 
  & .nav-arrow{
    font-family: "manrope";
    font-size: 1.2vw;
    position: absolute;
    transform: translate(4px,-4px);
    color: #c5111c;
    transition: all 0.3s ease;
  }
  /* Dropdown styling */
  .dropdown {
    position: relative;
  }


  .dropdown-content {
    transition: all 0.3s ease;
    transform: translateY(-10px);
    opacity: 0;
    visibility: hidden;
    position: absolute;
    width: 220px;
    top: 100%;
    left: 0;
    background-color: #ffffff;
    padding: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    list-style: none;
  }

  .sub-drop-down-content{
    transition: all 0.5s ease;
    transform: translateY(-10px);
    opacity: 0;
    width: 220px;
    visibility: hidden;
    position: absolute;
    top: -5px;
    left: -255px;
    background-color: #ffffff;
    padding: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    list-style: none;
  }

  .sub-drop-down-content li:hover{
    background-color: #c5111c;
  }

  .dropdown:hover .dropdown-content {
   visibility: visible;
   transform: translateY(0);
   opacity: 1;
  }
  .sub-drop-down{
    color: red;
  }
  .sub-drop-down:hover{
    background-color: #c5111c;
  }
  .sub-drop-down:hover .sub-drop-down-content {
   visibility: visible;
   transform: translateY(0);
   opacity: 1;
  }
  & .dropdown:hover .nav-arrow{
    transform: rotate(90deg) translate(-2px,-8px);
  }

  .dropdown-content li {
    padding: 8px 10px;
  }

  .dropdown-content li a {
    color: black;
    text-decoration: none;
  }
  @media screen and (max-width:767px) {
   flex-direction: column;
   padding: 0;
   margin: 0;
   width: 100%;
   gap: 10px;
  }
  @media screen and (min-width: 767px) and (max-width: 1023px){
    flex-direction: column;
   padding: 0;
   margin: 0;
   width: 100%;
   gap: 10px;
  }
`;
const MobLogo = styled.img`
display: none;


@media screen and (max-width:767px) {
  display: block;
   position: fixed;
  z-index: 20;
   left: 5vw;
   top: 5vw;
   width: 15vw;
  }
  @media screen and (min-width: 767px) and (max-width: 1023px){
    display: block;
   position: fixed;
  z-index: 20;
   left: 3vw;
   top: 3vw;
   width: 10vw;
  }
`
const NavToggle = styled.div`
display: none;

@media screen and (max-width:767px){
  display: block;
  width: 17vw;
height: 13vw;
position: fixed;
right: 5vw;
top: 5vw;
z-index: 20;
background-color: grey;
border-radius: 2vw;
display: flex;
flex-direction: column;
gap: 8px;
align-items: center;
justify-content: center;


.line{
  width: 80%;
  height: 4px;
  background-color: white;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.line:nth-child(2){
  width: 60%;
}
.line:nth-child(3){
  width: 40%;
}

&.active .line{
  transform: rotate(45deg) translateY(1vw) translateX(1vw);
}
&.active .line:nth-child(2){
  display: none;
}
&.active .line:nth-child(3){
  transform: rotate(-45deg) translateY(-1vw) translateX(1vw);
  width: 80%;
}

}
@media screen and (min-width: 767px) and (max-width: 1023px){
  display: block;
  width: 12vw;
height: 10vw;
position: fixed;
right: 3vw;
top: 3vw;
z-index: 20;
background-color: grey;
border-radius: 2vw;
display: flex;
flex-direction: column;
gap: 9px;
align-items: center;
justify-content: center;


.line{
  width: 80%;
  height: 4px;
  background-color: white;
  border-radius: 4px;
  transition: all 0.3s ease;
}
.line:nth-child(2){
  width: 60%;
}
.line:nth-child(3){
  width: 40%;
}

&.active .line{
  transform: rotate(45deg) translateY(0.7vw) translateX(0.7vw);
}
&.active .line:nth-child(2){
  display: none;
}
&.active .line:nth-child(3){
  transform: rotate(-45deg) translateY(-0.7vw) translateX(0.7vw);
  width: 80%;
}
}
`
export default function NavBar() {
  const [scrolled, setScrolled] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const HandleClick = () => setActive((prev) => !prev);

  return (
    <div>
         <MobLogo src={logo} alt="SLNIS LOGO" className='mob-logo'/>
      <NavToggle onClick={HandleClick} className={active ? 'active' : ''}>
       <div className="line"></div>
       <div className="line"></div>
       <div className="line"></div>
      </NavToggle>
      <NavContainer isActive ={active}  className={scrolled ? 'scrolled' : ''}>
        <Img src={logo} alt="SLNIS LOGO" />
        <NavList>
          <li><Link to="/" onClick={HandleClick}>HOME</Link></li>
          <li><Link to="/about" onClick={HandleClick}>ABOUT</Link></li>
          <li><Link to="/cafeteria" onClick={HandleClick}>CAFETERIA MANAGEMENT</Link></li>
          <li><Link to="/technology" onClick={HandleClick}>TECHNOLOGY</Link></li>
          <li><Link to="/facility" onClick={HandleClick}>FACILITY MANAGEMENT </Link></li>
          <li className="dropdown" onClick={HandleClick}>
           <Link to="/services">OUR SERVICES <span className='nav-arrow'>&gt;</span></Link> 
            <ul className="dropdown-content">
              <li className='sub-drop-down'><Link to="/services" >Facility Management Services</Link>
              </li>
              <li className='sub-drop-down'><Link to="/services" >Corporate Gifting</Link>
                <ul className="sub-drop-down-content">
                <li><Link to="">Premium Gifts</Link></li>
              <li><Link to="">Customized Gifts</Link></li>
              <li><Link to="">Tech Gifts</Link></li>
              <li><Link to="">Luxury Gifts</Link></li>
              <li><Link to="">Wellness Gifts</Link></li>
              <li><Link to="">Eco-Friendly Gifts</Link></li>
              <li><Link to="">Food and Beverage Gifts</Link></li>
              <li><Link to="">Travel Accessories</Link></li>
              <li><Link to="">Stationery and Office Gifts</Link></li>
              <li><Link to="">Premium Gifts</Link></li>
              <li><Link to="">Customized Gifts</Link></li>
              <li><Link to="">Tech Gifts</Link></li>
              <li><Link to="">Luxury Gifts</Link></li>
                </ul>
              </li>
              <li className='sub-drop-down'><Link to="/services" >Corporate Catering Services</Link>
                <ul className="sub-drop-down-content">
                <li><Link to="">Catering Services</Link></li>
              <li><Link to="">Food Delivery Services</Link></li>
              <li><Link to="">Cafeteria Management</Link></li>
              <li><Link to="">Concession Management</Link></li>
              <li><Link to="">Vending Services</Link></li>
              <li><Link to="">Other Food-Related Services</Link></li>
                </ul>
              </li>
              <li className='sub-drop-down'><Link to="/services" >Corporate Transport Services</Link>
              </li>
              <li className='sub-drop-down'><Link to="/services" > Event Management</Link>
                <ul className="sub-drop-down-content">
                <li><Link to="">Event Services</Link></li>
              <li><Link to="">Event Formats</Link></li>
              <li><Link to="">Corporate Event Activities</Link></li>
              <li><Link to="">Corporate Event Venues</Link></li>
                </ul>
              </li>
              {/* <li className='sub-drop-down'><Link to="/services" >Supply of Pantry, Stationery, and Housekeeping Consumables</Link>
                <ul className="sub-drop-down-content">
                <li><Link to="">Transport Services</Link></li>
              <li><Link to="">Corporate Leisure Travel</Link></li>
              <li><Link to="">Gifts</Link></li>
              <li><Link to="">Event Services</Link></li>
                </ul>
              </li> */}
              <li className='sub-drop-down'><Link to="/services" >Corporate/Apartment/Tech Park Landscape Solutions and Management</Link>
               
              </li>
              <li className='sub-drop-down'><Link to="/services" >Technology for Corporate Catering</Link>
          
              </li>
              <li className='sub-drop-down'><Link to="/services" >Corporate Travel Bookings and Offsite Training Arrangements</Link>
           
              </li>
            </ul>
          </li>
          <li><Link to="/contact" onClick={HandleClick}>CONTACT US</Link></li>
        </NavList>
      </NavContainer>
    </div>
  );
}
