import './App.css';
import React,{useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import About from './components/About';
import Home from './components/Home';
import NavBar from './components/NavBar';
import Services from './components/Services';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer';
import PageTransition from './components/PageTransition';
import CafeManagement from './components/CafeManagement';
import FacManagement from './components/FacManagement';
import Technology from './components/Technology';

function App() {
  const location = useLocation(); 

  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500); // Delay of 300ms

    return () => clearTimeout(timeout); // Cleanup function
  }, [location.pathname]);

  return (
    <>
      <NavBar />
      <AnimatePresence mode="wait">
        <Routes location={location} key={location.pathname}>
          <Route
            path="/"
            element={
              <PageTransition>
                <Home />
              </PageTransition>
            }
          />
          <Route
            path="/about"
            element={
              <PageTransition>
                <About />
              </PageTransition>
            }
          />
          <Route
            path="/services"
            element={
              <PageTransition>
                <Services />
              </PageTransition>
            }
          />
          <Route
            path="/contact"
            element={
              <PageTransition>
                <ContactUs />
              </PageTransition>
            }
          />
           <Route
            path="/cafeteria"
            element={
              <PageTransition>
              <CafeManagement />
              </PageTransition>
            }
          />
             <Route
            path="/technology"
            element={
              <PageTransition>
              <Technology />
              </PageTransition>
            }
          />
              <Route
            path="/facility"
            element={
              <PageTransition>
              <FacManagement />
              </PageTransition>
            }
          />
        </Routes>
      </AnimatePresence>
      <Footer />
    </>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;
