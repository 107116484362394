import React,{useEffect} from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import styled from 'styled-components';
import facManags from '../assets/images/facm.jpg'
import fac1 from '../assets/images/housekeeping.jpg'
import fac2 from '../assets/images/pest.png'
import fac3 from '../assets/images/tech.jpg'
import fac4 from '../assets/images/pantry.jpg'
import fac5 from '../assets/images/techcc.jpg'
import fac6 from '../assets/images/fleet.jpg'



const CafeManagTop = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(${facManags});
  background-size: cover;
  background-position: center;
  position: relative;

  & .CafeManag-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(rgba(255,255,255,0.6), white);
  }
  @media screen and (max-width:767px) {
  .CafeManag-overlay{
  width: 100%;
  height: 102%;
  }
}
@media screen and (min-width:767px) and (min-width:1023px) {
  .CafeManag-overlay{
  width: 100%;
  height: 102%;
  }
}
`;

const CafeManagH = styled.div`
  font-size: 3vw;
  font-family: "heebo";
  font-weight: 300;
  position: relative;

  & span{
      color: #c5111c;
      font-weight: 900;
  }
  @media screen and (max-width:767px) {
  font-size: 7vw;
  text-align: center;
}

@media screen and (min-width:767px) and (max-width:1023px) {
font-size: 5vw;
}
`;

const CafeManagPara = styled.div`
  font-weight: 500;
  font-family: "Source Sans 3";
  font-size: 1.1vw;
  position: relative;
`;


const CafBoxes = styled.div`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;


.CafBox{
    width: 75%;
    height: 33vw;
    display: flex;
    padding: 2vw 0vw;
}
 
.cafCont{
    width: 50%;
    height: 33vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.cafCont h2{
    text-transform: uppercase;
}

.cafImg{
    width: 50%;
    height: 33vw;
}


.cafImg{
    display: flex;
    align-items: center;
   justify-content: flex-end;
}
.CafBox:nth-child(2) .cafImg,.CafBox:nth-child(4) .cafImg,.CafBox:nth-child(6) .cafImg{
 justify-content:flex-start ;
}
.ci-img-box{
    width: 80%;
    height: 23vw;
    overflow: hidden;
    border: 6px solid rgba(0, 0, 0, 0.19);
    display: flex;
    align-items: center;
    justify-content: center;
}
.ci-img-box img{
    width: 100%;
}
@media screen and (max-width:767px) {
  .CafBox{
    flex-direction: column;
    height: auto;
    padding: 7vw 0vw;
    gap: 3vw;
  }
  .cafCont{
    width: 100%;
    height: auto;
  }
  .cafImg{
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
  }
  .cafCont h2 {
    font-size: 5vw;
}
.cafCont p {
    font-size: 3.5vw;
}
.ci-img-box{
    height: 60vw;
}
.CafBox:nth-child(2),.CafBox:nth-child(4),.CafBox:nth-child(6){
    flex-direction: column-reverse;
}
.CafBox:nth-child(2) .cafImg,.CafBox:nth-child(4) .cafImg,.CafBox:nth-child(4) .cafImg{
    justify-content: center;
}
}

@media screen and (min-width:767px) and (max-width:1023px) {
    .CafBox{
    flex-direction: column;
    height: auto;
    padding: 7vw 0vw;
    gap: 3vw;
  }
  .cafCont{
    width: 100%;
    height: auto;
  }
  .cafImg{
    width: 100%;
    height: auto;
    justify-content: center;
    align-items: center;
  }
  .cafCont h2 {
    font-size: 4vw;
}
.cafCont p {
    font-size: 2.5vw;
}
.ci-img-box{
    height: 45vw;
}
.CafBox:nth-child(2),.CafBox:nth-child(4),.CafBox:nth-child(6){
    flex-direction: column-reverse;
}
.CafBox:nth-child(2) .cafImg,.CafBox:nth-child(4) .cafImg,.CafBox:nth-child(4) .cafImg{
    justify-content: center;
}
}
`;


export default function FacManagement() {
       useEffect(() => {
            AOS.init({
              duration: 500,
            });
        
          }, []);
  return (
    <div>
             <CafeManagTop>
        <div className="CafeManag-overlay">

        </div>
       <CafeManagH>
       FACILITY <span>MANAGEMENT</span> SERVICES 
       </CafeManagH>
       <CafeManagPara>
       {/* From Concept to Completion, We've Got You Covered */}
       </CafeManagPara> 
      </CafeManagTop>

       <CafBoxes>
    <div className="CafBox">
        <div className="cafCont">
<h2>HOUSEKEEPING  SERVICES </h2>

<p>
At SVLNS Corporate Solutions, we specialize in providing top-notch housekeeping and janitorial services across a variety of sectors, including corporate offices, residential properties, banking sectors, and more.
<br /><br /> Our professional team is committed and trained to maintaining clean, hygienic, and safe environments tailored to meet the unique needs of each client. Whether it's ensuring spotless workspaces, well-maintained common areas, or hygienic premises.
<br /><br /> We utilize advanced cleaning techniques, eco-friendly products, and highly trained personnel to create an environment that is not only visually appealing but also conducive to productivity, health, and well-being.
<br /><br /> With SVLNS Corporate Solutions, you can trust us to provide reliable, efficient, and consistent cleaning services, ensuring your spaces remain welcoming and safe at all times.


</p>
        </div>
        <div className="cafImg">
   <div className="ci-img-box">
<img src={fac1} alt="" />
   </div>
        </div>
    </div>
    <div className="CafBox">
    <div className="cafImg">
   <div className="ci-img-box">
   <img src={fac2} alt="" />
   </div>
    </div>
   <div className="cafCont">
<h2>Pest Control services </h2>

<p>
At SVLNS Corporate Solutions, we offer comprehensive pest control services designed to ensure a clean, healthy, and pest-free environment 
<br /><br /> Our expert team specializes in the prevention and elimination of pests and rodents, providing tailored solutions to address your unique requirements. Using advanced techniques and environmentally friendly products, we ensure effective control and long-lasting results.
<br /><br />By maintaining a pest-free environment, we help you create a safe, hygienic, and productive space for employees, residents, and visitors alike. Whether you need routine pest management or targeted treatment for specific infestations, SVLNS Corporate Solutions delivers reliable and efficient services you can trust.


</p>
 </div>
    </div>
    <div className="CafBox">
        <div className="cafCont">
<h2>Landscaping services </h2>

<p>
At SVLNS Corporate Solutions, we provide landscaping solutions and services that transform your offices, residential properties, and premises into beautiful, lush, and green spaces.
<br /><br />Our expert team specializes in designing, maintaining, and enhancing landscapes that not only elevate the aesthetic appeal of your surroundings but also contribute to a serene and refreshing environment for employees and visitors.
<br /><br />With SVLNS Corporate Solutions, you can create and maintain green spaces that foster positivity, well-being, and a visually stunning environment that leaves a lasting impression.



</p>
        </div>
        <div className="cafImg">
   <div className="ci-img-box">
   <img src={fac3} alt="" />
   </div>
        </div>
    </div>
    <div className="CafBox">
    <div className="cafImg">
   <div className="ci-img-box">
   <img src={fac4} alt="" />
   </div>
    </div>
   <div className="cafCont">
<h2>Supply of HK and Pantry Consumable services </h2>

<p>
At SVLNS Corporate Solutions, we provide a comprehensive range of housekeeping (HK) and pantry consumables to meet the daily operational needs of your office.
<br /><br />From essential cleaning supplies to pantry items, we ensure the delivery of high-quality products that align with your workplace requirements. Our offerings include a wide variety of consumables such as cleaning agents, tissues, mops, garbage bags, disposable cups, cutlery, coffee, tea, and other pantry essentials.
<br /><br />We prioritize quality and reliability, ensuring that your office is always stocked with the necessary supplies.
<br /><br />With SVLNS Corporate Solutions, you can count on seamless supply management, ensuring your workplace runs smoothly and efficiently at all times.


</p>
 </div>
    </div>
    <div className="CafBox">
        <div className="cafCont">
<h2>Event Management and Corporate Gifting </h2>

<p>
At SVLNS Corporate Solutions, we offer Event Management Services and Corporate Gifting Solutions tailored to meet the unique needs of your organization.
<br /><br />Our event management services ensure seamless planning and execution of your corporate events, including annual days, team-building activities, product launches, and festive celebrations. We ensure delivering memorable experiences that reflect your company’s values and vision.
<br /><br />In addition, our corporate gifting solutions are designed to help you show appreciation to your employees, clients, and stakeholders. Whether it’s festive gifts, milestone celebrations, or tokens of appreciation for special occasions, we curate high-quality, personalized gifts that leave a lasting impression.



</p>
        </div>
        <div className="cafImg">
   <div className="ci-img-box">
   <img src={fac5} alt="" />
   </div>
        </div>
    </div>
    <div className="CafBox">
    <div className="cafImg">
   <div className="ci-img-box">
   <img src={fac6} alt="" />
   </div>
    </div>
    <div className="cafCont">
<h2>Fleet Management Services </h2>

<p>
At SVLNS Corporate Solutions, we provide reliable and efficient Fleet Management and Transport Services to ensure smooth and safe travel for your employees.
<br /><br />Our services are tailored to meet the commuting needs of your workforce, offering seamless home-to-office and back transportation solutions. With a strong focus on safety and convenience, we utilize modern vehicles equipped with advanced tracking systems, ensuring real-time monitoring and a secure travel experience.
<br /><br />We prioritize employee well-being by maintaining high standards of vehicle hygiene, regular maintenance, and adherence to safety protocols. Our professional drivers are trained to provide a comfortable and punctual commute, helping employees reach their destinations stress-free and on time.



</p>
        </div>
    </div>


       </CafBoxes>
    </div>
  )
}
